@import "/styles/variables.scss";
@import "/styles/mixin.scss";

.sectionBigTitle {
  font-size: $font-h1-d;
  margin: 0;

  @include mobile {
    font-size: $font-h1-m;
  }
}

.sectionTitle {
  font-size: $font-h2-d;
  margin: 0;

  @include mobile {
    font-size: $font-h2-m;
  }
}

.subTitle {
  font-size: $font-h3-d;
  margin: 0;

  @include mobile {
    font-size: $font-h3-m;
  }
}

@import "/styles/variables.scss";
@import "/styles/mixin.scss";

.header {
  display: hidden;

  @include tablet {
    width: 100%;
    display: block;
    align-items: center;
  }
}

.headerNav {
  position: relative;
  z-index: 8010;
  width: 100%;
  height: $height-header-m;
  padding: 0 32px;
  backdrop-filter: blur(16px);
  background-color: rgba(241, 242, 244, 0.8);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.08);
}

.isSubNavStuck {
  box-shadow: none;
}

.headerNavContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoWrapper {
  display: flex;
}

$button-line-spacing: 6px;

.menuButton {
  width: 24px;
  height: 24px;
  position: relative;
  transform: rotate(0deg) translateY(-1px);
  transition: .5s ease-in-out;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 6px 3px;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 18px;
    background: $color-neutral-100;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: $button-line-spacing;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: $button-line-spacing * 2;
    }

    &:nth-child(4) {
      top: $button-line-spacing * 3;
    }
  }

  .isMenuOpened & {
    span {
      &:nth-child(1) {
        top: $button-line-spacing * 2;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: $button-line-spacing * 2;
        width: 0%;
        left: 50%;
      }
    }
  }
}

.main {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transform: translateY(-100%);
  transition: 0.3s;

  .isMenuOpened & {
    transform: translateY(0);
  }
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 8000;
  overflow: scroll;
  padding-top: $height-header-m;
  padding-bottom: 110px;
  background-color: $color-neutral-900;
  box-sizing: border-box;
}

.mask {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7990;
  width: 100vw;
  height: 100vh;
  background-color: $color-neutral-200;

  .isMenuOpened & {
    display: block;
  }
}

.menuList {
  width: 100%;
  padding: 0;
  margin-bottom: 40px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  list-style: none;
  color: $color-neutral-200;
}

.menuItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 32px;
  line-height: 26px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: $green-mist;
  }
}

.buttonWrapper {
  padding: 0 32px;
}

.unexpandedSubMenuIcon,
.expandedSubMenuIcon {
  display: inline-block;
  font-size: 12px;
  margin-left: 12px;
}

.expandedSubMenuIcon {
  display: none;
}

.menuItemWrapper {
  &:hover {
    .unexpandedSubMenuIcon {
      display: none;
    }

    .expandedSubMenuIcon {
      display: inline-block
    }

    .subMenuList {
      display: flex;
    }
  }
}

.subMenuList {
  display: none;
  width: 100%;
  padding: 0;
  align-items: stretch;
  flex-direction: column;
  list-style: none;
  color: $color-neutral-200;
}

.subMenuItem {
  display: block;
  width: 100%;
  padding: 10px 32px 10px 48px;
  line-height: 26px;
  font-size: 16px;
  cursor: pointer;
  background-color: $color-neutral-900;

  &:hover {
    background-color: $green-mist;
  }
}
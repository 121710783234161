$width-desktop: 1960px;
$width-laptop: 1440px;
$width-large-tablet: 1024px;
$width-tablet: 768px;

@mixin laptop {
  @media(max-width: ($width-desktop - 1px)) {
    @content;
  }
}

@mixin large-tablet {
  @media(max-width: ($width-laptop - 1px)) {
    @content;
  }
}

@mixin tablet {
  @media(max-width: ($width-large-tablet - 1px)) {
    @content;
  }
}

@mixin mobile {
  @media(max-width: ($width-tablet - 1px)) {
    @content;
  }
}

@mixin desktopOnly {
  display: block;

  @include mobile {
    display: none;
  }
}

@mixin mobileOnly {
  display: none;

  @include mobile {
    display: block;
  }
}

@mixin smallDotList {
  padding: 0;
  margin: 0;
  vertical-align: middle;
  padding-left: 1em;

  >li {
    position: relative;
    margin: 0px;
    letter-spacing: 0.02em;
    list-style: none;

    &:before {
      content: "．";
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      font-size: inherit;
      text-align: center;
      line-height: inherit;
      font-weight: bold;
    }
  }
}

@mixin markdownContent {
  width: 100%;
  padding: 24px;
  padding-bottom: 12px;
  color: $color-neutral-200;
  line-height: 160%;
  letter-spacing: 0.02em;

  >p {
    margin: 0;
  }

  >img {
    width: 100%;
  }
}

@mixin imageWrapper($percentage) {
  &:before {
    content: '';
    width: 100%;
    display: block;
    padding-bottom: $percentage;
  }
}

@mixin contentMaxLimit($additionDesktopWidth,
  $additionLaptopWidth,
  $additionLTabletWidth,
  $additionTabletWidth,
  $additionMobilePadding) {
  margin: 0 auto;
  width: calc($width-content-d + $additionDesktopWidth);

  @include laptop {
    width: calc($width-content-l + $additionLaptopWidth);
  }

  @include large-tablet {
    width: calc($width-content-lt + $additionLTabletWidth);
  }

  @include tablet {
    width: calc($width-content-t + $additionTabletWidth);
  }

  @include mobile {
    margin: 0;
    padding: calc($padding-width-m - $additionMobilePadding);
    width: 100%;
  }
}

@mixin slideInFromTop($top, $duration) {
  @keyframes slideInFromTop {
    from {
      opacity: 0;
      top: 0;
    }
    to {
      opacity: 1;
      top: $top;
    }
  }

  animation-name: slideInFromTop;
  animation-duration: $duration;
}
@import "/styles/variables.scss";
@import "/styles/mixin.scss";

.sectionWrapper {
  padding: $padding-height-d 0;
  @include contentMaxLimit(0px, 0px, 0px, 0px, 0px);

  @include mobile {
    margin: 0;
    padding: $padding-height-m $padding-width-m;
  }
}

.title {
  margin-bottom: 40px;
}

.backgroundWrapper {
  background-color: $green-mist;
}
@import "/styles/variables.scss";
@import "/styles/mixin.scss";

.eventOpen {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
    right: 32px;
    bottom: 40px;

    @include mobile {
        right: 12px;
        bottom: 16px;
    }
}

.eventClose {
    position: fixed;
    z-index: 5;
    right: 40px;
    bottom: 40px;

    @include mobile {
        right: 16px;
        bottom: 16px;
    }
}

.eventMenu {
    padding: 16px 12px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
    border-radius: 60px;
    background-color: $color-neutral-900;
    margin-bottom: 16px;
}

.topButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    font-size: 16px;
    cursor: pointer;

    @include mobile {
        font-size: 12px;
    }
}

.event {
    display: block;
    margin: 0 auto;
    margin-bottom: 16px;
    font-size: 16px;
    width: 40px;
    line-height: 130%;
    text-align: center;

    @include mobile {
        font-size: 12px;
        width: 30px;
    }
}
/* naming rules 

generic-specific-device 

--- device shorthanded ---
-d: desktop
-l: laptop
-lt: large-tablet
-t: tablet
-m: mobile

*/

/* color */
$gray: #333333;
$gray-light: #F5F5F5;
$gray-bright: #F2F2F2;
$gray-dark: #c4c4c4;
$gray-neutral: #A9A9A9;
$green-deep: #003816;
$green-tree: #00702D;
$green-grass: #37995E;
$green-mist: #F2F9F5;

$color-neutral-900: #FFFFFF;
$color-neutral-800: #E2E2E2;
$color-neutral-200: #333333;
$color-neutral-100: #323232;
$color-neutral-000: #000000;


/* component size */
$height-header-d: 70px;
$height-header-m: 56px;

/* page width */
$width-content-d: 1440px;
$width-content-l: 1146px;
$width-content-lt: 960px; 
$width-content-t: 700px;
$width-content-m: 320px;

/* page padding */
$padding-height-d: 100px;
$padding-width-d: 120px;
$padding-height-m: 40px;
$padding-width-m: 32px;

/* page margin */
$margin-width-m: 32px;

/* font */
$font-h1-d: 48px;
$font-h2-d: 32px;
$font-h3-d: 24px;
$font-h1-m: 36px;
$font-h2-m: 24px;
$font-h3-m: 18px;
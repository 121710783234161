@import "/styles/variables.scss";

@mixin icon-original {
  .iconOriginal {
    display: inline-block;
  }
  .iconHover {
    display: none;
  }
}

@mixin icon-hover {
  .iconOriginal {
    display: none;
  }
  .iconHover {
    display: inline-block;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.12em;
  transition: 0.3s;
  background-color: transparent;

  @include icon-original;
}

.outline {
  border: 1.5px solid $green-tree;
  background-color: transparent;
  color: $green-tree;

  &:hover {
    background-color: $green-tree;
    color: $gray-bright;
    @include icon-hover;
  }

  &:active {
    background-color: $green-deep;
  }
}

.outline--reverse {
  border: 1.5px solid $gray-bright;
  background-color: transparent;
  color: $gray-bright;

  &:hover {
    background-color: $gray-bright;
    color: $green-tree;
    @include icon-hover;
  }

  &:active {
    background-color: $gray-dark;
  }
}

.noBorder {
  border: none;

  &:hover {
    background-color: $green-tree;
    color: $gray-bright;
    @include icon-hover;
  }

  &:active {
    background-color: $green-deep;
  }
}

.iconWrapper {
  margin-left: 8px;
  height: 24px;
}

.iconWrapper[data-position='left'] {
  transform: rotateY(180deg);
  margin-left: 0;
  margin-right: 8px;
}

.button:disabled {
  background-color: $gray-neutral;
  border-color: $gray-neutral;
  color: white;
  cursor: not-allowed;
}

@import "/styles/variables.scss";
@import "/styles/mixin.scss";

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  > main {
    width: 100%;
    margin-top: $height-header-d;

    @include tablet {
      margin-top: $height-header-m;
    }
  }

  > header {
    position: fixed;
    top: 0;
    z-index: 9000;
  }
}

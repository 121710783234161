@import "/styles/variables.scss";
@import "/styles/mixin.scss";

html,
body {
  padding: 0;
  margin: 0;
  color: $color-neutral-200;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 2%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: 'Mulish', 'Noto Sans TC', sans-serif;
  font-style: normal;
  font-weight: normal;
}

h1 {
  font-size: $font-h1-d;
  color: $gray;
  margin: 0;
}

h2 {
  font-size: $font-h2-d;
  color: $gray;
  margin: 0;
}

h3 {
  font-size: $font-h3-d;
  color: $gray;
  margin: 0;
}

p {
  color: $gray;
  margin: 0;
}

textarea:focus, input:focus{
  outline: none;
}

@include mobile {
  h1 {
    font-size: $font-h1-m;
  }
  
  h2 {
    font-size: $font-h2-m;
  }
  
  h3 {
    font-size: $font-h3-m;
  }
}